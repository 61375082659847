import React from 'react';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';

const PrivacyPolicyPage = () => {
    loadCrispChat();

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <div className='flex justify-content-center'>
                <div style={{ maxWidth: '80vw' }}>
                    <h1>Privacy Policy</h1>
                    <p>By interacting with us, submitting Information to us, or signing up for any
                        promotions or services offered by us, you agree. Consent to Lofi.co its related corporations and
                        affiliates<strong>&nbsp;(collectively referred to herein as &nbsp;Lofi.co&nbsp; &nbsp;us,&nbsp; &nbsp;we&nbsp; or &nbsp;our&nbsp;), as
                            well as our respective representatives, collecting, using, disclosing, and
                            sharing&nbsp;</strong>amongst themselves your Personal Information, and disclosing such
                        Personal Information to Lofi.co authorized service providers and relevant third parties in the
                        manner outlined in this<strong>&nbsp;Privacy Policy.</strong></p><p>&nbsp;</p><p><strong>We
                        value your privacy and have taken the necessary measure to protect it.</strong></p><p>&nbsp;</p>
                    <p><strong>What is Personal Information?</strong></p><p>&nbsp;Personal Information&nbsp; refers to any
                            information or Information about you that can be used to identify you either from the
                            Information we have or are likely to access. In this context, such Information</p><p>maybe
                            as follows:</p>
                    <ol>
                        <li>Your name, identification number, telephone number(s), e-mail address or mailing
                                address, and any other information relating to you have provided us in any form you may
                                have submitted to us or other forms of interaction with you.&nbsp;</li>
                        <li>Information about your use of Lofi.co&nbsp;s website and services, including cookies, IP
                                address, subscription account details, and membership details, but only to the extent
                                that Lofi.co may identify you from such information.
                        </li>
                        <li>Information about your usage of and interaction with our website and services, including
                                computer and connection information, device capability, bandwidth, statistics on page
                                views, and traffic to and from our website.
                        </li>
                    </ol>
                    <p><strong>How is personal information collected or obtained?</strong></p><p>We are likely to
                            collect your personal information through the following channels: -</p>
                    <ol>
                        <li>On our website when you reach out to us for our services.</li>
                        <li>Contact us for customer service or for other business purposes through our contact
                                information such as our email.
                        </li>
                    </ol>
                    <p><strong>How is the collected personal information used?</strong></p><p>&nbsp;</p>
                    <ul>
                        <li>We will use your information to contact you regarding any services you will need and any
                                related purposes. We may contact you via regular E-mail, SMS, Telephone, or other
                                electronic means.
                        </li>
                        <li>If we need to use your information for any other purposes, we will notify you and obtain
                                your consent beforehand. You will be allowed to withhold or withdraw your consent to use
                                your information for these additional purposes.
                        </li>
                    </ul>
                    <p>&nbsp;</p><p><strong>How is your personal information disclosed?</strong></p><p>
                        <strong>&nbsp;</strong></p>
                    <ol>
                        <ol>
                            <li>We will not sell your information to third parties.</li>
                            <li>Disclosure of your Information to third parties shall only where you have provided
                                    us consent and, in the situations, expressly set out in this Policy.&nbsp;</li>
                            <li>Your Information may be disclosed and shared within the Lofi.co to provide the
                                    requested products and services.
                            </li>
                            <li>We may disclose or share your Information with third parties (including other
                                    companies within the Lofi.co who provide necessary services to us).
                            </li>
                            <li>We will also disclose your information to third parties to comply with legal
                                    obligations or industry requirements. This includes disclosures to legal,
                                    regulatory, governmental, tax, and law enforcement authorities.
                            </li>
                            <li>Our website may have the functionalities to share your Information with other third
                                    parties, such as other users of our website. You are responsible for your choice(s)
                                    and are deemed to have provided consent for any sharing of your Information in the
                                    manner provided by the website.
                            </li>
                        </ol>
                    </ol>
                    <p>&nbsp;</p><p><strong>DISCLAIMER</strong>: Our websites may contain links to other websites
                            which are not owned or maintained by us. When visiting these third-party websites or
                            disclosing your information to third parties (including buyers or sellers on our website),
                            you should read their privacy policies or ask relevant questions before disclosing your
                            Information. We are not responsible for collecting, using, or telling your Information by
                            such third parties.</p><p>&nbsp;</p><p><strong>Storage and processing of
                            information</strong></p><p>All information collected through the Website will be stored and
                            processed within the United States of America.</p><p>By registering for and using the
                            Website, you consent to the transfer of information to the Italy or to any other country in
                            which we maintain facilities and the use and disclosure of information about you as
                            described in this Privacy Policy.</p><p>We use commercially reasonable safeguards to help
                            keep the information collected through the Website secure and take reasonable steps (such as
                            requesting a unique password) to verify your identity before granting you access to your
                            account. To help us keep your information safe, we expect you to maintain the secrecy of
                            your unique password and account information.</p><p>&nbsp;</p><p><strong>Data
                            retention</strong></p><p>&nbsp;</p><p>We shall retain personal data collected for a
                            reasonable time frame, which will vary depending on the nature of the data and whether the
                            data may be needed for future legitimate use.</p><p>Following termination or deactivation of
                            your website account, we may retain information (including your profile information) for a
                            commercially reasonable time for backup, archival, and/or audit purposes.</p><p>&nbsp;</p>
                    <p><strong>Your control over information</strong></p><p>&nbsp;</p><p>You may, at any time, edit,
                            update or delete your profile information from the Website. However, when you delete or
                            update information, we follow a process to ensure that the modification or deletion is not
                            accidental or malicious. Accordingly, there may be delays between when you edit/delete
                            information and when copies are edited/deleted from our active and backup systems.</p>
                    <p>&nbsp;</p><p><strong>Changes to the Privacy Policy</strong></p><p>&nbsp;</p><p>We may update
                            this privacy policy from time to time. If a revision to the Privacy Policy, we shall
                            endeavor to provide you with reasonable notice prior to the revised Privacy Policy taking
                            effect. </p><p>&nbsp;</p><p><strong>CONTACT INFORMATION</strong></p><p>&nbsp;</p><p>For any
                            more information or clarification, please contact us through the following: -</p>
                    <p>&nbsp;</p><p><strong>E-mail: hello@lofi.co</strong></p><p>&nbsp;</p><p>&nbsp;</p>
                </div>
            </div>
        </MainLayout>
    );
};

export default PrivacyPolicyPage;